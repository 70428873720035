h1 {
  text-align: center;
}

.contact {
  padding: 4rem;
  opacity: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  box-shadow: inset 0 0 0 1000px #c0e7c8;
  object-fit: contain;
  flex-grow: 1;
}

.contact-container {
  padding: 5rem;
  color: #242424;
  ;
  background-color: #242424;
  ;
  opacity: 1;
  margin: 2vw 20vw 6vw 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

/*display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
justify-content: space-between;*/

/* Social Icons */

.contact-icon-link {
  color: #fff;
  font-size: 35px;
  padding: 20px;
}

.btn--contact {
  padding: 6px 12px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: rgb(250, 248, 248);
  border: 1.5px solid var(--primary);
  transition: all 0.3s ease-out;
  font-size: 20px;
  align-self: center;
  margin-top: 5vw;
}

.btn--contact:hover {
  transition: all 0.3s ease-out;
  background: white;
  color: black;
  transition: 250ms;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
  .footer-input {
    width: 100%;
  }
  .btn {
    width: 100%;
  }
  .footer-link-wrapper {
    flex-direction: column;
  }
  .contact-icons {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media screen and (max-width: 768px) {}