.footer-container {
  background-color: #242424;
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0px;
  color: #242424;
  justify-content: space-between;
}

/* Social Icons */

.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.btn--cv {
  padding: 6px 12px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: rgb(250, 248, 248);
  border: 1.5px solid var(--primary);
  transition: all 0.3s ease-out;
  display: flex;
  align-self: flex-end;
  justify-self: end;
  font-size: large;
}

.btn--cv:hover {
  transition: all 0.3s ease-out;
  background: white;
  color: black;
  transition: 250ms;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5 rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
  .footer-input {
    width: 100%;
  }
  .btn {
    width: 100%;
  }
  .footer-link-wrapper {
    flex-direction: column;
  }
  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 420px) {
  .btn--cv {
    padding: 6px 12px;
   
  }
}