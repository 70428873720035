h1 {
  text-align: center;
}

.about {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background-color: #c0e7c8;
  object-fit: contain;
}

.about-container {
  padding: 2rem;
  color: #242424;
  background-color: #242424;
  opacity: 1;
  margin: 2vw 10vw 6vw 10vw;
}

.profilePic {
  float: right;
  width: 15rem;
  padding: 15px;
  border-radius: 50%;
}

.textAbout {
  color: white;
  font-size: 1.3em;
}

@media screen and (max-width: 820px) {
  .about-container {
    width: 100%;
  }
  .footer-link-wrapper {
    flex-direction: column;
  }
  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 410px) {
  .about-container {
    width: 100%;
  }
  .about {
    padding: 1.2rem;
  }
  .profilePic {
    display: block;
    margin-left: auto;
    margin-right: auto;
   float: none;
  }
}

@media screen and (max-width: 768px) {}