/*video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}*/

.hero-container {
  background: url('../girl.webp') center center/cover no-repeat;
  opacity: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(#c0e7c8, 0.2);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container>h1 {
  color: #fff;
  font-size: 100px;
  text-shadow: 3px 3px #58052c;
  margin-top: -100px;
}

.hero-container>p {
  width: 80%;
  margin-top: 8px;
  color: rgb(255, 255, 255);
  font-size: 25px;
  text-shadow: 3px 3px rgb(75, 75, 72);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: rgba(0, 0, 0, 0.2);
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container>h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container>h1 {
    font-size: 50px;
    margin-top: -0px;
  }
  .hero-container>p {
    font-size: 30px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .hero-container>h1 {
    font-size: 0;
    margin: 0;
  }
}