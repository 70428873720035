h1 {
  text-align: center;
}

.blog {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background-color: #c0e7c8;
  flex-grow: 1;
}

.blog-container {
  padding: 2rem;
  padding-top: 1rem;
  color: white;
  color: #242424;
  opacity: 1;
  width: 95%;
}

.allPosts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #242424;
}

.text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  color: #242424;
  align-self: center;
  word-wrap: break-word;
}

.blogSpan {
  background-color: #242424;
  box-shadow: 0 4px 8px 0 rgba(32, 32, 32, 0.5), 0 6px 20px 0 rgba(41, 32, 32, 0.5);
}

@media screen and (max-width: 820px) {
  .blog-container {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .blog-container {
    width: 100%;
    background-color: #c0e7c8;
    padding-top: 0;
  }
  .blog {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {}