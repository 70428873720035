h1 {
  text-align: center;
}

.singlePostContainer {
  padding: 20px;
  padding-top: 70px;
  padding-bottom: 70px;
  color: rgb(12, 12, 12);
  background-color: #c0e7c8;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
  line-height: 1.7;
}

.postCard {
  padding: 20px;
  background-color: white;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.postImage {
  padding: 10px;
}

.postContent {
  color: black;
}

@media screen and (max-width: 820px) {
  .blog-container {
    width: 100%;
  }
  .footer-link-wrapper {
    flex-direction: column;
  }
  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {}